import React from "react";
import styled from "styled-components";

const Logo = ({ classes }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 331.33 183.11"
    className={`${classes ? classes : "text-brand-500"}`}
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="onblue">
        <g id="form" class="mis-cls-1">
          <polygon
            class="mis-cls-2"
            points="191.83 53.01 192.02 23.42 0 101.13 0 183.11 174.62 183.11 174.62 48.97 191.83 53.01"
          />
          <polygon class="mis-cls-3" points="191.83 53.01 191.01 183.11 315 183.11 315 81.88 191.83 53.01" />
          <polygon class="mis-cls-4" points="174.62 48.97 174.62 183.11 191.01 183.11 191.83 53.01 174.62 48.97" />
        </g>
        <polygon
          class="mis-cls-5"
          points="135.26 50.51 135.26 138.78 145.76 138.78 145.76 63.75 202.32 77.01 202.32 66.23 135.26 50.51"
        />
        <polygon
          class="mis-cls-5"
          points="212.82 68.69 212.82 79.47 320.83 104.79 320.83 150.46 245.68 150.46 245.68 160.96 331.33 160.96 331.33 96.47 212.82 68.69"
        />
        <path
          class="mis-cls-5"
          d="M22,151.45h1l.15,1.56a3.22,3.22,0,0,1,1.25-1.26,3.67,3.67,0,0,1,1.81-.43h1v1.18h-1a3,3,0,0,0-2.11.72,2.58,2.58,0,0,0-.81,1.92V161H22Z"
        />
        <path
          class="mis-cls-5"
          d="M32.31,161.08a3.94,3.94,0,0,1-3-1.15,4.61,4.61,0,0,1-1.1-3.31v-.84a4.57,4.57,0,0,1,1.11-3.3,3.92,3.92,0,0,1,3-1.16,3.89,3.89,0,0,1,3,1.16,4.57,4.57,0,0,1,1.11,3.3v.78H29.51v.06a3.56,3.56,0,0,0,.76,2.47,2.68,2.68,0,0,0,2,.82,2.81,2.81,0,0,0,1.79-.5,2.92,2.92,0,0,0,.91-1.23H36.3a4.7,4.7,0,0,1-.46,1.14,3.18,3.18,0,0,1-.8.92,4.07,4.07,0,0,1-1.17.62A5,5,0,0,1,32.31,161.08Zm0-8.58a2.56,2.56,0,0,0-2,.77,3.41,3.41,0,0,0-.83,2.19h5.57a3.22,3.22,0,0,0-.83-2.22A2.61,2.61,0,0,0,32.31,152.5Z"
        />
        <path
          class="mis-cls-5"
          d="M38.84,151.45h1L40,153a3.31,3.31,0,0,1,1.23-1.26,3.43,3.43,0,0,1,1.72-.43,3.33,3.33,0,0,1,1.78.47,2.93,2.93,0,0,1,1.16,1.43,3.29,3.29,0,0,1,1.27-1.41,3.65,3.65,0,0,1,1.91-.49,3.68,3.68,0,0,1,1.35.24,2.86,2.86,0,0,1,1,.75,3.61,3.61,0,0,1,.68,1.24,5.78,5.78,0,0,1,.23,1.7V161H51.08v-5.7a3,3,0,0,0-.63-2.06,2.2,2.2,0,0,0-1.71-.69,2.38,2.38,0,0,0-1,.19,2.23,2.23,0,0,0-.79.54,2.86,2.86,0,0,0-.55.83,3.1,3.1,0,0,0-.23,1.08V161H45v-5.7a3,3,0,0,0-.63-2.06,2.17,2.17,0,0,0-1.71-.69,2.38,2.38,0,0,0-1,.19,2.19,2.19,0,0,0-.78.54,2.51,2.51,0,0,0-.55.83,2.89,2.89,0,0,0-.23,1.08V161H38.84Z"
        />
        <path
          class="mis-cls-5"
          d="M58.87,161.08a4.7,4.7,0,0,1-1.72-.3,3.71,3.71,0,0,1-1.32-.87,3.87,3.87,0,0,1-.85-1.4,5.77,5.77,0,0,1-.29-1.89v-.84a5.47,5.47,0,0,1,.3-1.87,4.17,4.17,0,0,1,.85-1.4,3.51,3.51,0,0,1,1.31-.89,5.08,5.08,0,0,1,3.44,0,3.73,3.73,0,0,1,1.32.88,3.64,3.64,0,0,1,.84,1.4,5.58,5.58,0,0,1,.3,1.88v.84a5.43,5.43,0,0,1-.3,1.87,4.32,4.32,0,0,1-.85,1.41,3.56,3.56,0,0,1-1.32.88A4.73,4.73,0,0,1,58.87,161.08Zm0-1.17a2.76,2.76,0,0,0,2.09-.85,3.43,3.43,0,0,0,.8-2.44v-.84a3.4,3.4,0,0,0-.81-2.43,3,3,0,0,0-4.17,0,3.43,3.43,0,0,0-.8,2.44v.84a3.4,3.4,0,0,0,.81,2.43A2.74,2.74,0,0,0,58.87,159.91Z"
        />
        <path
          class="mis-cls-5"
          d="M68.65,161.08a3.89,3.89,0,0,1-1.5-.28A3.18,3.18,0,0,1,66,160a4,4,0,0,1-.76-1.39,6.05,6.05,0,0,1-.28-1.94v-.84a6,6,0,0,1,.28-1.92,4.18,4.18,0,0,1,.76-1.4,3.21,3.21,0,0,1,1.18-.86,3.89,3.89,0,0,1,1.5-.28,3.76,3.76,0,0,1,1.75.38,3.13,3.13,0,0,1,1.26,1.14v-5.19h1.25V161h-1l-.17-1.6a3.15,3.15,0,0,1-1.27,1.31A3.94,3.94,0,0,1,68.65,161.08Zm.29-1.17a2.74,2.74,0,0,0,1.08-.22,2.55,2.55,0,0,0,.86-.63,2.91,2.91,0,0,0,.57-1,4.36,4.36,0,0,0,.21-1.41v-1a3.43,3.43,0,0,0-.81-2.33,2.55,2.55,0,0,0-1.91-.8,2.6,2.6,0,0,0-2,.8,3.6,3.6,0,0,0-.74,2.48v.84a3.56,3.56,0,0,0,.75,2.47A2.57,2.57,0,0,0,68.94,159.91Z"
        />
        <path
          class="mis-cls-5"
          d="M79.45,161.08a3.94,3.94,0,0,1-3-1.15,4.61,4.61,0,0,1-1.1-3.31v-.84a4.57,4.57,0,0,1,1.11-3.3,4.39,4.39,0,0,1,6,0,4.57,4.57,0,0,1,1.11,3.3v.78H76.65v.06a3.56,3.56,0,0,0,.76,2.47,2.68,2.68,0,0,0,2,.82,2.81,2.81,0,0,0,1.79-.5,2.9,2.9,0,0,0,.9-1.23h1.3a5.14,5.14,0,0,1-.46,1.14,3.18,3.18,0,0,1-.8.92,4,4,0,0,1-1.18.62A4.92,4.92,0,0,1,79.45,161.08Zm0-8.58a2.56,2.56,0,0,0-2,.77,3.41,3.41,0,0,0-.83,2.19h5.57a3.22,3.22,0,0,0-.83-2.22A2.62,2.62,0,0,0,79.45,152.5Z"
        />
        <path
          class="mis-cls-5"
          d="M86.62,160.31a2.49,2.49,0,0,1-.64-1.87V147.65h1.26v10.64c0,1,.45,1.52,1.36,1.52h.61V161h-.76A2.44,2.44,0,0,1,86.62,160.31Z"
        />
        <path class="mis-cls-5" d="M92,149.78a.89.89,0,1,1,.64-.26A.86.86,0,0,1,92,149.78Zm-.62,1.67h1.25V161H91.38Z" />
        <path
          class="mis-cls-5"
          d="M95.84,151.45h1L97,153a3.32,3.32,0,0,1,1.29-1.26,3.85,3.85,0,0,1,1.87-.43,3.36,3.36,0,0,1,2.57,1,4.09,4.09,0,0,1,.94,2.94V161h-1.25v-5.7a2.94,2.94,0,0,0-.65-2.07,2.34,2.34,0,0,0-1.8-.68,2.86,2.86,0,0,0-2,.72,2.61,2.61,0,0,0-.8,1.92V161H95.84Z"
        />
        <path
          class="mis-cls-5"
          d="M109.71,160.7a3.92,3.92,0,0,1-1.52-.28,3.34,3.34,0,0,1-1.17-.83,4,4,0,0,1-.76-1.34,5.66,5.66,0,0,1-.27-1.82v-.84a5.56,5.56,0,0,1,.27-1.81,4,4,0,0,1,.77-1.34,3.27,3.27,0,0,1,1.18-.84,3.89,3.89,0,0,1,1.5-.28,4,4,0,0,1,1.86.42,3.07,3.07,0,0,1,1.28,1.27l.15-1.56h1V161a4,4,0,0,1-1,2.92,3.82,3.82,0,0,1-2.84,1,3.87,3.87,0,0,1-2.65-.78,3.17,3.17,0,0,1-1-2.1h1.26a2.1,2.1,0,0,0,.68,1.26,2.68,2.68,0,0,0,1.73.45,2.62,2.62,0,0,0,2-.67,3,3,0,0,0,.63-2.09v-1.73a3,3,0,0,1-1.25,1.11A3.92,3.92,0,0,1,109.71,160.7Zm.29-1.17a2.83,2.83,0,0,0,1.07-.2,2.5,2.5,0,0,0,.85-.59,2.91,2.91,0,0,0,.57-.93,3.64,3.64,0,0,0,.22-1.23v-1.12a3.1,3.1,0,0,0-.78-2.19,2.56,2.56,0,0,0-1.93-.77,2.61,2.61,0,0,0-2,.78,3.29,3.29,0,0,0-.74,2.31v.84a3.23,3.23,0,0,0,.75,2.3A2.59,2.59,0,0,0,110,159.53Z"
        />
        <path
          class="mis-cls-5"
          d="M128.94,159.47a5,5,0,0,1-1.71,1.21,5.37,5.37,0,0,1-2.09.4,5,5,0,0,1-1.7-.27,4,4,0,0,1-1.28-.76,3.2,3.2,0,0,1-.82-1.14,3.59,3.59,0,0,1-.28-1.44,4.25,4.25,0,0,1,.1-.95,3.59,3.59,0,0,1,.36-.91,4.65,4.65,0,0,1,.71-.92,9.14,9.14,0,0,1,1.18-1,10.89,10.89,0,0,1-.88-1,5.18,5.18,0,0,1-.52-.86,2.69,2.69,0,0,1-.26-.77,3.8,3.8,0,0,1-.07-.77,2.82,2.82,0,0,1,.21-1.09,2.33,2.33,0,0,1,.61-.87,2.85,2.85,0,0,1,1-.58,3.71,3.71,0,0,1,1.29-.21,3.54,3.54,0,0,1,1.26.21,2.71,2.71,0,0,1,1,.58,2.33,2.33,0,0,1,.61.87,2.82,2.82,0,0,1,.21,1.09,3.63,3.63,0,0,1-.09.8,2.6,2.6,0,0,1-.35.85,4.77,4.77,0,0,1-.75.93,9.44,9.44,0,0,1-1.3,1.05l3.46,3.59a6.31,6.31,0,0,0,.43-1.27,7.36,7.36,0,0,0,.16-1.54h1.29a7.55,7.55,0,0,1-.26,2.08,7,7,0,0,1-.69,1.7l2.38,2.49h-1.7Zm-3.8.44a3.82,3.82,0,0,0,1.61-.35,4,4,0,0,0,1.34-1l-3.82-4a9.86,9.86,0,0,0-1,.81,3.87,3.87,0,0,0-.59.71,2.28,2.28,0,0,0-.28.68,3.79,3.79,0,0,0-.06.67,2.24,2.24,0,0,0,.74,1.76A2.93,2.93,0,0,0,125.14,159.91ZM123,150.27a2.93,2.93,0,0,0,.06.61,2.18,2.18,0,0,0,.23.59,5,5,0,0,0,.46.66,11,11,0,0,0,.74.86c.46-.33.84-.62,1.12-.86a3.27,3.27,0,0,0,.61-.69,1.71,1.71,0,0,0,.26-.6,2.76,2.76,0,0,0,.06-.57,1.5,1.5,0,0,0-.48-1.12,1.76,1.76,0,0,0-1.27-.45,1.83,1.83,0,0,0-1.31.45A1.5,1.5,0,0,0,123,150.27Z"
        />
        <path
          class="mis-cls-5"
          d="M141.54,161.08a4.42,4.42,0,0,1-1.66-.3,3.54,3.54,0,0,1-1.3-.87,3.83,3.83,0,0,1-.83-1.4,5.51,5.51,0,0,1-.3-1.89v-.84a5.47,5.47,0,0,1,.3-1.87,3.83,3.83,0,0,1,.83-1.4,3.47,3.47,0,0,1,1.3-.89,4.42,4.42,0,0,1,1.66-.3,4.36,4.36,0,0,1,1.41.21,3.55,3.55,0,0,1,1.09.58,3.12,3.12,0,0,1,.76.86,4.41,4.41,0,0,1,.44,1.05h-1.33a2.86,2.86,0,0,0-.82-1.09,2.44,2.44,0,0,0-1.55-.43,2.65,2.65,0,0,0-2,.84,3.47,3.47,0,0,0-.79,2.44v.84A4.38,4.38,0,0,0,139,158a3,3,0,0,0,.59,1,2.66,2.66,0,0,0,.89.64,2.82,2.82,0,0,0,1.11.22,2.38,2.38,0,0,0,1.56-.46,2.89,2.89,0,0,0,.81-1.25h1.33a3.93,3.93,0,0,1-1.19,2.07A3.66,3.66,0,0,1,141.54,161.08Z"
        />
        <path
          class="mis-cls-5"
          d="M151,161.08a4.7,4.7,0,0,1-1.72-.3,3.62,3.62,0,0,1-1.32-.87,3.87,3.87,0,0,1-.85-1.4,5.77,5.77,0,0,1-.29-1.89v-.84a5.47,5.47,0,0,1,.3-1.87,4,4,0,0,1,.85-1.4,3.51,3.51,0,0,1,1.31-.89,5.08,5.08,0,0,1,3.44,0,3.73,3.73,0,0,1,1.32.88,3.64,3.64,0,0,1,.84,1.4,5.58,5.58,0,0,1,.3,1.88v.84a5.2,5.2,0,0,1-.31,1.87,4.13,4.13,0,0,1-.84,1.41,3.56,3.56,0,0,1-1.32.88A4.73,4.73,0,0,1,151,161.08Zm0-1.17a2.76,2.76,0,0,0,2.09-.85,3.43,3.43,0,0,0,.8-2.44v-.84a3.4,3.4,0,0,0-.81-2.43,3,3,0,0,0-4.17,0,3.43,3.43,0,0,0-.8,2.44v.84a3.4,3.4,0,0,0,.81,2.43A2.74,2.74,0,0,0,151,159.91Z"
        />
        <path
          class="mis-cls-5"
          d="M157.65,151.45h1l.15,1.56a3.3,3.3,0,0,1,1.28-1.26,3.91,3.91,0,0,1,1.87-.43,3.4,3.4,0,0,1,2.58,1,4.09,4.09,0,0,1,.94,2.94V161h-1.25v-5.7a2.94,2.94,0,0,0-.65-2.07,2.35,2.35,0,0,0-1.81-.68,2.84,2.84,0,0,0-2,.72,2.66,2.66,0,0,0-.81,1.92V161h-1.25Z"
        />
        <path
          class="mis-cls-5"
          d="M171.41,161.08a3.77,3.77,0,0,1-2.61-.77,3.18,3.18,0,0,1-1-2.11h1.29a2,2,0,0,0,.63,1.29,2.67,2.67,0,0,0,1.69.42,3.08,3.08,0,0,0,1.81-.4,1.34,1.34,0,0,0,.5-1.12,1.15,1.15,0,0,0-.42-1,3.36,3.36,0,0,0-1.07-.54,13.85,13.85,0,0,0-1.38-.35,6.45,6.45,0,0,1-1.37-.43,3,3,0,0,1-1.07-.79,2.13,2.13,0,0,1-.42-1.4,2.36,2.36,0,0,1,.84-1.86,3.79,3.79,0,0,1,2.58-.74,3.53,3.53,0,0,1,2.44.75,3.11,3.11,0,0,1,1,2h-1.3a1.89,1.89,0,0,0-.61-1.12,2.29,2.29,0,0,0-1.51-.4,2.68,2.68,0,0,0-1.66.39,1.28,1.28,0,0,0-.47,1,1,1,0,0,0,.42.87,3.79,3.79,0,0,0,1.07.47q.63.18,1.38.33a6,6,0,0,1,1.37.46,3,3,0,0,1,1.07.84,2.41,2.41,0,0,1,.43,1.5,2.45,2.45,0,0,1-.88,1.93A4.07,4.07,0,0,1,171.41,161.08Z"
        />
        <path
          class="mis-cls-5"
          d="M176.33,151.45H178v-2.66h1.25v2.66h2.55v1.14h-2.55v5.7c0,1,.47,1.52,1.41,1.52H182V161h-1.49a2.23,2.23,0,0,1-2.5-2.51v-5.85h-1.71Z"
        />
        <path
          class="mis-cls-5"
          d="M184.1,151.45h1l.15,1.56a3.2,3.2,0,0,1,1.24-1.26,3.71,3.71,0,0,1,1.82-.43h.95v1.18h-.95a3,3,0,0,0-2.11.72,2.63,2.63,0,0,0-.82,1.92V161H184.1Z"
        />
        <path
          class="mis-cls-5"
          d="M194.43,161.08a3.28,3.28,0,0,1-2.49-1,4.16,4.16,0,0,1-.93-2.95v-5.7h1.26v5.7a3,3,0,0,0,.63,2.07,2.24,2.24,0,0,0,1.72.69,2.61,2.61,0,0,0,2.74-2.76v-5.7h1.25V161h-1l-.15-1.56a3.31,3.31,0,0,1-1.26,1.26A3.69,3.69,0,0,1,194.43,161.08Z"
        />
        <path
          class="mis-cls-5"
          d="M205.15,161.08a4.42,4.42,0,0,1-1.66-.3,3.54,3.54,0,0,1-1.3-.87,3.83,3.83,0,0,1-.83-1.4,5.51,5.51,0,0,1-.3-1.89v-.84a5.47,5.47,0,0,1,.3-1.87,3.83,3.83,0,0,1,.83-1.4,3.47,3.47,0,0,1,1.3-.89,4.42,4.42,0,0,1,1.66-.3,4.36,4.36,0,0,1,1.41.21,3.44,3.44,0,0,1,1.09.58,3.12,3.12,0,0,1,.76.86,4.41,4.41,0,0,1,.44,1.05h-1.33a2.86,2.86,0,0,0-.82-1.09,2.42,2.42,0,0,0-1.55-.43,2.65,2.65,0,0,0-2,.84,3.47,3.47,0,0,0-.78,2.44v.84a4.39,4.39,0,0,0,.2,1.4,3,3,0,0,0,.59,1,2.66,2.66,0,0,0,.89.64,2.82,2.82,0,0,0,1.11.22,2.38,2.38,0,0,0,1.56-.46,2.89,2.89,0,0,0,.81-1.25h1.33a3.93,3.93,0,0,1-1.19,2.07A3.66,3.66,0,0,1,205.15,161.08Z"
        />
        <path
          class="mis-cls-5"
          d="M209.88,151.45h1.71v-2.66h1.25v2.66h2.55v1.14h-2.55v5.7c0,1,.47,1.52,1.41,1.52h1.33V161H214.1a2.25,2.25,0,0,1-2.51-2.51v-5.85h-1.71Z"
        />
        <path
          class="mis-cls-5"
          d="M218.47,149.78a.89.89,0,1,1,.63-.26A.87.87,0,0,1,218.47,149.78Zm-.63,1.67h1.25V161h-1.25Z"
        />
        <path
          class="mis-cls-5"
          d="M225.91,161.08a4.65,4.65,0,0,1-1.71-.3,3.66,3.66,0,0,1-1.33-.87,4,4,0,0,1-.84-1.4,5.51,5.51,0,0,1-.3-1.89v-.84a5.47,5.47,0,0,1,.31-1.87,4,4,0,0,1,.84-1.4,3.54,3.54,0,0,1,1.32-.89,5.05,5.05,0,0,1,3.43,0,3.56,3.56,0,0,1,1.32.88,3.79,3.79,0,0,1,.85,1.4,5.58,5.58,0,0,1,.3,1.88v.84a5.43,5.43,0,0,1-.31,1.87,4.13,4.13,0,0,1-.84,1.41,3.68,3.68,0,0,1-1.33.88A4.65,4.65,0,0,1,225.91,161.08Zm0-1.17a2.78,2.78,0,0,0,2.1-.85,3.43,3.43,0,0,0,.79-2.44v-.84a3.44,3.44,0,0,0-.8-2.43,3,3,0,0,0-4.18,0,3.43,3.43,0,0,0-.79,2.44v.84a3.44,3.44,0,0,0,.8,2.43A2.75,2.75,0,0,0,225.91,159.91Z"
        />
        <path
          class="mis-cls-5"
          d="M232.55,151.45h1l.15,1.56a3.23,3.23,0,0,1,1.28-1.26,3.88,3.88,0,0,1,1.87-.43,3.4,3.4,0,0,1,2.58,1,4.13,4.13,0,0,1,.94,2.94V161h-1.26v-5.7a2.93,2.93,0,0,0-.64-2.07,2.36,2.36,0,0,0-1.81-.68,2.84,2.84,0,0,0-2,.72,2.62,2.62,0,0,0-.81,1.92V161h-1.25Z"
        />
        <path
          fill="currentColor"
          d="M32.7,130.07v8.71H22.15V116.39H34.21l1.69,6.81,1.69-6.81H49.65v22.39H39.11v-8.71L35.9,135.4Z"
        />
        <path
          fill="currentColor"
          d="M65.67,136.48H63l-.14,2.3H51.17l7.12-22.39H70.35l7.17,22.39H65.82Zm-.36-6.59-1-6.8-.93,6.8Z"
        />
        <path fill="currentColor" d="M116.22,123.23v1.26h7.34v6.19h-7.34v1.26h7.34v6.84H106V116.39h17.53v6.84Z" />
        <path fill="currentColor" d="M168.77,116.39v8.28h-4.93v14.11H153.29V124.67h-4.93v-8.28Z" />
        <path
          fill="currentColor"
          d="M226.06,130.07v8.71H215.51V116.39h12.06l1.69,6.81,1.69-6.81H243v22.39H232.46v-8.71l-3.2,5.33Z"
        />
        <path
          fill="currentColor"
          d="M190,139.36a45.81,45.81,0,0,1-10-1.3V131h7.23c.4,0,.61-.11.61-.47s-.21-.5-.61-.54c-5.25-.36-8.06-2.8-8-6.94,0-4.79,3.14-7.13,9.51-7.2a37.87,37.87,0,0,1,10.26,1.33v7h-6.7c-.47,0-.61.18-.61.51s.25.47.65.5c5.72.51,8,2.56,8,7C200.21,137,196.75,139.36,190,139.36Z"
        />
        <path
          fill="currentColor"
          d="M269.15,124.46c0,5.65-4.22,8.06-11.23,8.06h-1.7v6.26H245.68V116.39h12.24C265.65,116.39,269.15,118.81,269.15,124.46Zm-10.95,0a1.42,1.42,0,0,0-1.62-1.62h-.36v3.24h.36A1.42,1.42,0,0,0,258.2,124.46Z"
        />
        <path fill="currentColor" d="M289,130.5v8.28H271.16V116.39h10.55V130.5Z" />
        <path fill="currentColor" d="M301.83,123.23v1.26h7.35v6.19h-7.35v1.26h7.35v6.84H291.65V116.39h17.53v6.84Z" />
        <polygon
          fill="currentColor"
          points="79.07 54.12 79.07 138.78 89.57 138.78 89.57 129.87 91.98 138.78 104.5 138.78 99.82 126.98 104.07 116.39 91.87 116.39 89.57 124.63 89.57 61.19 202.32 15.57 202.32 138.78 212.82 138.78 212.82 0 79.07 54.12"
        />
      </g>
    </g>
  </svg>
);

export default Logo;

const cityTable = {
  1013527: "Alameda",
  1013528: "Alamo",
  1013529: "Albany",
  1013541: "American Canyon",
  1013542: "Anaheim",
  1013544: "Angels Camp",
  1013547: "Antelope",
  1013548: "Antioch",
  1013554: "Arnold",
  1013563: "Auburn",
  1013570: "Bakersfield",
  1013581: "Belmont",
  1013582: "Belvedere Tiburon",
  1013584: "Benicia",
  1013585: "Berkeley",
  1013602: "Bodega Bay",
  1013614: "Brentwood",
  1013617: "Brisbane",
  1013636: "Calistoga",
  1013642: "Campbell",
  1013651: "Carlsbad",
  1013654: "Carmichael",
  1013659: "Castro Valley",
  1013665: "Ceres",
  1013671: "Chico",
  1013678: "Citrus Heights",
  1013682: "Clearlake",
  1013691: "Colfax",
  1013697: "Concord",
  1013704: "Corte Madera",
  1013715: "Cupertino",
  1013718: "Daly City",
  1013720: "Danville",
  1013721: "Davis",
  1013734: "Dixon",
  1013740: "Dublin",
  1013745: "Dutch Flat",
  1013753: "El Cerrito",
  1013755: "El Dorado Hills",
  1013759: "El Sobrante",
  1013763: "Elk Grove",
  1013765: "Elverta",
  1013766: "Emeryville",
  1013774: "Eureka",
  1013776: "Fair Oaks",
  1013777: "Fairfax",
  1013778: "Fairfield",
  1013789: "Folsom",
  1013795: "Foresthill",
  1013802: "Fremont",
  1013810: "Galt",
  1013817: "Gilroy",
  1013826: "Grass Valley",
  1013842: "Half Moon Bay",
  1013848: "Hayward",
  1013883: "Irvine",
  1013886: "Jackson",
  1013919: "Lafayette",
  1013932: "Larkspur",
  1013945: "Lincoln",
  1013950: "Livermore",
  1013953: "Lodi",
  1013959: "Loomis",
  1013961: "Los Altos",
  1013964: "Los Gatos",
  1013981: "Manteca",
  1013988: "Martinez",
  1013989: "Marysville",
  1013990: "Mather",
  1013997: "Meadow Vista",
  1014002: "Menlo Park",
  1014009: "Mill Valley",
  1014010: "Millbrae",
  1014012: "Milpitas",
  1014013: "Mira Loma",
  1014019: "Modesto",
  1014034: "Morgan Hill",
  1014044: "Mountain View",
  1014046: "Napa",
  1014052: "Newark",
  1014055: "Newcastle",
  1014066: "North Highlands",
  1014073: "Novato",
  1014080: "Oakland",
  1014092: "Orangevale",
  1014093: "Orinda",
  1014098: "Oroville",
  1014102: "Pacifica",
  1014109: "Palo Alto",
  1014126: "Penryn",
  1014129: "Petaluma",
  1014134: "Piedmont",
  1014139: "Pinole",
  1014141: "Pittsburg",
  1014144: "Placerville",
  1014147: "Pleasant Grove",
  1014148: "Pleasant Hill",
  1014149: "Pleasanton",
  1014150: "Plymouth",
  1014166: "Rancho Cordova",
  1014178: "Redwood City",
  1014183: "Rescue",
  1014187: "Richmond",
  1014199: "Rocklin",
  1014201: "Rohnert Park",
  1014204: "Roseville",
  1014208: "San Jose",
  1014215: "San Bruno",
  1014216: "San Carlos",
  1014221: "San Francisco",
  1014225: "San Joaquin",
  1014226: "San Jose",
  1014237: "San Mateo",
  1014242: "San Rafael",
  1014243: "San Ramon",
  1014257: "Santa Rosa",
  1014260: "Saratoga",
  1014261: "Sausalito",
  1014262: "Scotts Valley",
  1014264: "Seaside",
  1014265: "Sebastopol",
  1014274: "Shingle Springs",
  1014280: "Sloughhouse",
  1014288: "Sonoma",
  1014293: "South Lake Tahoe",
  1014295: "South San Francisco",
  1014300: "St. Helena",
  1014305: "Stinson Beach",
  1014306: "Stockton",
  1014310: "Suisun City",
  1014315: "Sunnyvale",
  1014323: "Tahoe City",
  1014341: "Tracy",
  1014346: "Truckee",
  1014357: "Union City",
  1014361: "Vacaville",
  1014363: "Vallejo",
  1014377: "Walnut Creek",
  1014391: "West San Jose",
  1014406: "Wilton",
  1014408: "Windsor",
  1014411: "Winters",
  1014415: "Woodland",
  1014419: "Yolo",
  1014424: "Yuba City",
  9051519: "Arden-Arcade",
  9051682: "Cameron Park",
  9051835: "Discovery Bay",
  9051956: "Florin",
  9051959: "Foothill Farms",
  9051977: "Foster City",
  9052035: "Gold River",
  9052048: "Granite Bay",
  9052136: "Hillsborough",
  9052251: "La Riviera",
  9052728: "Rancho Murieta",
  9052771: "Rosemont",
  9053056: "Vineyard",
  9032516: "Roseville",
  9032482: "Roseville",
  9032443: "Folsom",
  9032518: "Elk Grove",
  9032424: "Carmichael",
  9032457: "Lincoln",
  9032536: "Rosemont",
  9032466: "Roseville",
  9032519: "El Dorado Hills",
  9056631: "Rancho Cordova",
  9032481: "Rocklin",
  9032515: "Granite Bay",
  9032419: "Auburn",
  9032520: "Placer County",
  9032543: "Natomas",
  9032551: "Antelope",
  9032441: "Fair Oaks",
  9032537: "San Jose",
  9032430: "Davis",
  9032425: "Citrus Heights",
  9032435: "Citrus Heights",
  9032200: "Stockton",
  9032209: "Stockton",
};

export const cityName = (city, def = "San Jose & Bay") => {
  if (city == "sac") {
    return "San Jose";
  }
  if (city == "bayarea") {
    return "Bay Area";
  }
  return def;
};

export const textReplacement = (personalizedTextRef, replacementContainerClassName, textToReplaceWith) => {
  let personalizedElments;
  if (personalizedTextRef.current) {
    personalizedElments = personalizedTextRef.current.getElementsByClassName(replacementContainerClassName);
    for (const personalizedElement of personalizedElments) {
      personalizedElement.innerText = textToReplaceWith;
    }
  }
};

// remove
export const getCity = () => {
  try {
    const { city } = JSON.parse(window.sessionStorage.getItem("rFprAn"));
    return city;
  } catch (e) {
    return null;
  }
};

//rename to valuetrackparams
export const getLocPhysicalAndCity = () => {
  try {
    const { city, locphysical, campaigns } = JSON.parse(window.sessionStorage.getItem("rFprAn"));
    return { city, locphysical, campaigns };
  } catch (e) {
    return {};
  }
};

// returns a string with value tracked city name for the header
export const adRefCity = (def) => {
  //for build process
  try {
    const { locphysical, city } = JSON.parse(window.sessionStorage.getItem("rFprAn"));
    // no params passed return default
    if (!(locphysical || city)) {
      return def;
    }
    const cityNameFromTable = cityTable[locphysical];
    // check if city exists before returning new string
    // check if city is passed then alter string
    if (!!city && cityNameFromTable === undefined) {
      return `Serving ${cityName(city)} & Surrounding Areas`;
    }
    // if cityName is not found return default
    if (!cityNameFromTable) {
      return def;
    }
    // return the cityName when found
    return `Serving ${cityNameFromTable}, CA and surrounding areas`;
  } catch (e) {
    // for build step, return the default string
    return def;
  }
};

// returns a string with value tracked city name for text replacement
export const adRefCityText = (def, { city, locphysical }) => {
  try {
    // no params passed return default
    if (!(locphysical || city)) {
      return def;
    }
    const cityNameFromTable = cityTable[locphysical];
    // check if city exists before returning new string
    // check if city is passed then alter string
    if (!!city && cityNameFromTable === undefined) {
      return cityName(city);
    }
    // if cityName is not found return default
    if (!cityNameFromTable) {
      return def;
    }
    // return the cityName when found
    return cityNameFromTable;
  } catch (e) {
    // for build step, return the default string
    return def;
  }
};
